import { Box, Button, CircularProgress, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import "./Highlights.css";
import { Images } from "../../../../helpers/Images";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { access, corpus, Key, q } from "../../../../helpers/Arrays";
import { listFilesGoogle } from "../../../../helpers/functions";
import { GalleryArray } from "../../../../helpers/gallery";



const Highlights = () => {
  const navigate = useNavigate();
  const [images, setImages] = useState();
  const [loading, setLoading] = useState(false);

  // useEffect(() => {
  //   listFilesGoogle(setImages, 1000, setLoading);
  // }, []);

  return (
    <Box
      sx={{
        width: "100%",
        backgroundColor: "primary.main",
        borderTop: "2px solid white",
      }}
    >
      <Box
        sx={{
          width: "100%",
          padding: { xs: "50px 20px", sm: "50px 60px", lg: "70px 120px" },
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          maxWidth: "1500px",
          margin: "auto",
        }}
      >
        <Typography
          variant={"h2"}
          sx={{
            textAlign: "center",
            fontSize: "35px",
            fontWeight: "500",
            color: "secondary.main",
            marginBottom: "5px",
          }}
        >
          WeDo Challenge Highlights
        </Typography>
        <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
          <Button
            variant="contained"
            color="secondary"
            sx={{
              fontSize: "15px",
              margin: "10px 0 40px 0",
              fontWeight: "600",
              color: "primary.main",
              // backgroundColor: "secondary.main",
            }}
            onClick={() => navigate("/gallery")}
          >
            WeDo Gallery
          </Button>
        </Box>
        {loading ? (
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              margin: "100px 0",
            }}
          >
            <CircularProgress color="secondary" />
          </Box>
        ) : (
          <Grid container spacing={3} justifyContent={"center"}>
            {GalleryArray?.sort((a, b) => 0.5 - Math.random())
              ?.slice(0, 8)
              ?.map((item, i) => (
                <Grid item xs={12} sm={6} md={6} lg={3} key={i}>
                  <img
                    loading={"lazy"}
                    alt={i}
                    src={item}
                    style={{ width: "100%",
                    borderRadius: "10px"}}
                  />
                </Grid>
              ))}
          </Grid>
        )}
      </Box>
      
    </Box>
  );
};

export default Highlights;

