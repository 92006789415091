import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Grid,
  Typography,
} from "@mui/material";
import React, { useRef, useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Images } from "../../helpers/Images";
import emailjs from "@emailjs/browser";
import { useLocation } from "react-router-dom";
import axios from "axios";

const ContactTemplate = () => {
  // const location = useLocation();
  // console.log();
  // const [state, setState] = useState({
  //   fname: "",
  //   lname: "",
  //   email: "",
  //   message: "",
  //   mailSent: false,
  //   error: null,
  // });
  // const API_PATH = `${window.location.origin}/API/index.php`;

  // const handleFormSubmit = (event) => {
  //   event.preventDefault();
  //   console.log(state);
  //   axios({
  //     method: "post",
  //     url: `${API_PATH}`,
  //     headers: { "content-type": "application/json" },
  //     data: state,
  //   })
  //     .then((result) => {
  //       setState({
  //         mailSent: result.data.sent,
  //       });
  //     })
  //     .catch((error) => setState({ error: error.message }));
  // };
  return (
    <>
      <Box
        sx={{
          width: "100%",
          padding: { xs: "50px 20px", sm: "50px 60px", lg: "50px 120px" },
          maxWidth: "1500px",
          margin: "auto",
        }}
      >
        {/* <form method="post" action="main.php">
          <label>First Name</label>
          <input
            type="text"
            id="fname"
            name="firstname"
            placeholder="Your name.."
            value={state?.fname}
            onChange={(e) => setState({ ...state, fname: e.target.value })}
          />
          <label>Last Name</label>
          <input
            type="text"
            id="lname"
            name="lastname"
            placeholder="Your last name.."
            value={state?.lname}
            onChange={(e) => setState({ ...state, lname: e.target.value })}
          />

          <label>Email</label>
          <input
            type="email"
            id="email"
            name="email"
            placeholder="Your email"
            value={state?.email}
            onChange={(e) => setState({ ...state, email: e.target.value })}
          />

          <label>Subject</label>
          <textarea
            id="subject"
            name="subject"
            placeholder="Write something.."
            value={state?.message}
            onChange={(e) => setState({ ...state, message: e.target.value })}
          ></textarea>
          <input
            type="submit"
            value="Submit"
            onClick={(e) => handleFormSubmit(e)}
          />
        </form>
        <div>{state?.mailSent && <div>Thank you for contacting us.</div>}</div> */}
        <Typography
          variant={"h1"}
          sx={{
            textAlign: "center",
            fontSize: { xs: "40px", sm: "55px" },
            fontWeight: "500",
            color: "primary.darker",
          }}
        >
          Get In Touch
        </Typography>
        <Box
          sx={{
            height: "3px",
            maxWidth: { xs: "100px", md: "150px" },
            width: "100%",
            backgroundColor: "primary.main",
            margin: "10px auto",
          }}
        />
        <Typography
          sx={{
            textAlign: "center",
            fontSize: "20px",
            fontWeight: "400",
            color: "primary.darker",
            marginTop: "20px",
          }}
        >
          Want to get in touch? We'd love to hear from you. Here's how you can
          reach us..
        </Typography>
        <Grid container spacing={5} sx={{ marginTop: "30px" }}>
          <Grid item xs={12} md={6}>
            <Box
              sx={{
                width: "100%",
                padding: "30px",
                backgroundColor: "primary.main",
                borderRadius: "10px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <img
                alt="call"
                src={Images.call}
                style={{
                  width: "80px",
                  filter:
                    "invert(100%) sepia(8%) saturate(243%) hue-rotate(147deg) brightness(113%) contrast(100%)",
                  marginTop: "5px",
                  marginBottom: "10px",
                }}
              />
              <Typography
                variant={"h3"}
                sx={{
                  textAlign: "center",
                  fontSize: "25px",
                  fontWeight: "400",
                  color: "secondary.main",
                }}
              >
                Talk?
              </Typography>
              <Typography
                sx={{
                  textAlign: "center",
                  fontSize: "17px",
                  fontWeight: "300",
                  color: "secondary.main",
                  marginTop: "20px",
                  width: "80%",
                }}
              >
                Interested to participate in We Do Challenge this year? Just
                pick up the phone to call a member of our team..
              </Typography>
              {/* <a
                href={"tel:+201022758877"}
                style={{ textDecoration: "none", marginTop: "30px" }}
              >
                <Typography
                  variant={"h3"}
                  sx={{
                    textAlign: "center",
                    fontSize: "25px",
                    fontWeight: "400",
                    color: "secondary.main",
                  }}
                >
                  +201022758877
                </Typography>
              </a> */}
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "20px",
                }}
              >
                <a
                  href={"tel:+201022758877"}
                  style={{ textDecoration: "none" }}
                >
                  <Button
                    variant="contained"
                    color="secondary"
                    sx={{
                      fontSize: "15px",
                      fontWeight: "600",
                      color: "primary.main",
                    }}
                  >
                    Call now
                  </Button>
                </a>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box
              sx={{
                width: "100%",
                padding: "30px",
                backgroundColor: "primary.main",
                borderRadius: "10px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                height: "100%",
                justifyContent: "space-between",
              }}
            >
              <img
                alt="chat"
                src={Images.chat}
                style={{
                  width: "80px",
                  filter:
                    "invert(100%) sepia(8%) saturate(243%) hue-rotate(147deg) brightness(113%) contrast(100%)",
                  marginTop: "5px",
                  marginBottom: "10px",
                }}
              />
              <Typography
                variant={"h3"}
                sx={{
                  textAlign: "center",
                  fontSize: "25px",
                  fontWeight: "400",
                  color: "secondary.main",
                }}
              >
                Chat?
              </Typography>
              <Typography
                sx={{
                  textAlign: "center",
                  fontSize: "17px",
                  fontWeight: "300",
                  color: "secondary.main",
                  marginTop: "20px",
                  width: "80%",
                }}
              >
                Want some help? Have any question? Just click here to
                immediately chat with us..
              </Typography>
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "20px",
                }}
              >
                <a
                  target="_blank"
                  href="https://api.whatsapp.com/send?phone=%2B201022758877&fbclid=IwAR2Q0ES6pK05-OcugrLDDzFvd5qdd6SrzjAz07-6vnp_FUFVhxwE5pCxTXE"
                  style={{ textDecoration: "none" }}
                >
                  <Button
                    variant="contained"
                    color="secondary"
                    sx={{
                      fontSize: "15px",
                      fontWeight: "600",
                      color: "primary.main",
                    }}
                  >
                    Chat now
                  </Button>
                </a>
              </Box>
            </Box>
          </Grid>
        </Grid>

        <Grid container spacing={5} sx={{ marginTop: "10px" }}>
          <Grid item xs={12} md={6}>
            <Box
              sx={{
                width: "100%",
                padding: "30px",
                backgroundColor: "primary.main",
                borderRadius: "10px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <img
                alt="call"
                src={Images.mail}
                style={{
                  width: "100px",
                  filter:
                    "invert(100%) sepia(8%) saturate(243%) hue-rotate(147deg) brightness(113%) contrast(100%)",
                  marginTop: "5px",
                  marginBottom: "10px",
                }}
              />
              <Typography
                variant={"h3"}
                sx={{
                  textAlign: "center",
                  fontSize: "25px",
                  fontWeight: "400",
                  color: "secondary.main",
                }}
              >
                Any Inquiries?
              </Typography>
              <Typography
                sx={{
                  textAlign: "center",
                  fontSize: "17px",
                  fontWeight: "300",
                  color: "secondary.main",
                  marginTop: "20px",
                  width: "80%",
                }}
              >
                For any inquiries or to connect via email, please reach out to
                us at: wedochallenge1@gmail.com
              </Typography>

              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "20px",
                }}
              >
                <a
                  target="_blank"
                  href="mailto:wedochallenge1@gmail.com"
                  style={{ textDecoration: "none" }}
                >
                  <Button
                    variant="contained"
                    color="secondary"
                    sx={{
                      fontSize: "15px",
                      fontWeight: "600",
                      color: "primary.main",
                    }}
                  >
                    Send Mail
                  </Button>
                </a>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box
              sx={{
                width: "100%",
                padding: "30px",
                backgroundColor: "primary.main",
                borderRadius: "10px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                height: "100%",
                justifyContent: "space-between",
              }}
            >
              <img
                alt="chat"
                src={Images.community}
                style={{
                  width: "100px",
                  filter:
                    "invert(100%) sepia(8%) saturate(243%) hue-rotate(147deg) brightness(113%) contrast(100%)",
                  marginTop: "5px",
                  marginBottom: "10px",
                }}
              />
              <Typography
                variant={"h3"}
                sx={{
                  textAlign: "center",
                  fontSize: "25px",
                  fontWeight: "400",
                  color: "secondary.main",
                }}
              >
                Join Our Community!
              </Typography>
              <Typography
                sx={{
                  textAlign: "center",
                  fontSize: "17px",
                  fontWeight: "300",
                  color: "secondary.main",
                  marginTop: "20px",
                  width: "80%",
                }}
              >
                All updates will be shared exclusively on our WhatsApp group.
                Let's connect and grow together!
              </Typography>
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "20px",
                }}
              >
                <a
                  target="_blank"
                  href="https://whatsapp.com/channel/0029VafuisvA89MZzeUs631Y"
                  style={{ textDecoration: "none" }}
                >
                  <Button
                    variant="contained"
                    color="secondary"
                    sx={{
                      fontSize: "15px",
                      fontWeight: "600",
                      color: "primary.main",
                    }}
                  >
                    join now
                  </Button>
                </a>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>

      <Box
        id={"questions"}
        sx={{
          width: "100%",
          padding: { xs: "50px 20px", sm: "50px 60px", lg: "50px 120px" },
          maxWidth: "1500px",
          margin: "auto",
        }}
      >
        <Typography
          variant={"h1"}
          sx={{
            textAlign: "center",
            fontSize: { xs: "40px" },
            fontWeight: "500",
            color: "primary.darker",
          }}
        >
          Questions & Answers
        </Typography>
        <Box
          sx={{
            height: "3px",
            maxWidth: { xs: "100px", md: "200px" },
            width: "100%",
            backgroundColor: "primary.main",
            margin: "10px auto 0 auto",
          }}
        />
        <Box id={"questions"} sx={{ marginTop: "50px" }}>
          {[
            {
              question: "What is the We Do Challenge Championship?",
              answer:
                "The We Do Challenge Championship is a global competition designed to inspire young minds aged 4 to 10 years by introducing them to the exciting world of STEAM (Science, Technology, Engineering, Arts, and Mathematics).",
            },
            {
              question:
                "Who can participate in the We Do Challenge Championship?",
              answer:
                "The competition is open to children aged 4-10 years old.",
            },
            {
              question: "How does the competition work?",
              answer:
                "Each year, a unique global challenge is revealed. Teams are tasked with designing, building, and programming robots that will help address the problem presented.",
            },
            {
              question:
                "Is there a required tool kit to participate in the We Do Challenge?",
              answer:
                "No, participants can use various materials, such as LEGO kits or even handmade robots, to build their creations.",
            },
            {
              question: "What are the age requirements for participants?",
              answer:
                "The competition is divided into two age groups: We Do Researchers: for children aged 4 to 6 years old We Do Engineers: for children aged 6 to 10 years old",
            },
            {
              question:
                "What type of robots can teams build for the competition?",
              answer:
                "Teams are encouraged to build their robots using materials like LEGO kits, handmade parts, or any other available tools. The goal is to be creative and resourceful in developing solutions to the given challenge. Your robot should meet the competition's specifications and be able to perform tasks relevant to solving the problem.",
            },
            {
              question:
                "Are there any workshops or mentorship programs to help teams?",
              answer:
                "Yes, there are usually workshops, webinars, or mentorship programs available for participants. These programs are designed to help teams with the basics of robot design, programming, and project management, ensuring they are prepared",
            },
          ].map((item) => (
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography>{item.question}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>{item.answer}</Typography>
              </AccordionDetails>
            </Accordion>
          ))}
        </Box>
      </Box>
    </>
  );
};

export default ContactTemplate;
