import { Box, Button, Grid, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { Images } from "../../../../helpers/Images";
import { categoriesData } from "../../../../helpers/Arrays";
import { Gallery } from "../../../../helpers/gallery";

const SumoTemplate = () => {
  const navigate = useNavigate();
  return (
    <>
      <Box
        sx={{
          width: "100%",
          padding: { xs: "50px 20px", sm: "50px 60px", lg: "50px 120px" },
          maxWidth: "1500px",
          margin: "auto",
        }}
      >
        {false ? (
          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography
              variant={"h1"}
              sx={{
                textAlign: "start",
                fontSize: { xs: "40px", sm: "70px" },
                fontWeight: "500",
                color: "primary.darker",
                margin: "100px auto 0 auto",
              }}
            >
              COMING SOON
            </Typography>
            <Box
              sx={{
                height: "3px",
                maxWidth: { xs: "100px", md: "300px" },
                width: "100%",
                backgroundColor: "primary.main",
                margin: "5px auto 5px auto",
              }}
            />
            <Typography
              variant={"h2"}
              sx={{
                textAlign: "start",
                fontSize: { xs: "40px", sm: "40px" },
                fontWeight: "500",
                color: "primary.darker",
                margin: "5px auto 100px auto",
              }}
            >
              WeDo Challenge
            </Typography>
          </Box>
        ) : (
          <Grid
            spacing={4}
            alignItems={"start"}
            justifyContent={"space-between"}
          >
            <Grid item xs={12} lg={7}>
              <Typography
                variant={"h1"}
                sx={{
                  textAlign: "center",
                  fontSize: { xs: "40px", sm: "55px" },
                  fontWeight: "500",
                  color: "#595c61",
                }}
              >
                Robo Guard
              </Typography>
              <Box
                sx={{
                  height: "3px",
                  maxWidth: { xs: "100px", md: "150px" },
                  width: "100%",
                  backgroundColor: "#c11a83",
                  margin: "auto",
                  marginTop: "5px",
                }}
              />
              <Typography
                sx={{
                  textAlign: "left",
                  color: "primary.darker",
                  // maxWidth: "750px",
                  margin: "25px 0 10px 0",
                  fontSize: "17px",
                }}
              >
                <Grid item xs={12} lg={4} sx={{ textAlign: "center" }}>
                  <br />
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                   {/*} <a
                      download
                      // href={
                      //   "https://drive.google.com/uc?export=download&id=1oL9V_SncMLCxa3qy4birOMdaPjGPDURF"
                      // }
                    >
                      <Button
                        variant="contained"
                        color="primary"
                        sx={{
                          fontSize: "17px",
                          margin: "5px 0",
                          fontWeight: "600",
                          backgroundColor: "#c11a83",
                        }}
                      >
                        Download Detailed Rules (PDF)
                      </Button>
                    </a> */}
                  </Box>
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  ></Box>
                </Grid>
                <Typography
                  variant={"h1"}
                  sx={{
                    textAlign: "start",
                    fontSize: { xs: "30px" },
                    fontWeight: "500",
                    color: "#595c61",
                    marginTop: "50px",
                    marginBottom: "10px",
                  }}
                >
                  Brief:
                </Typography>
                The Robo Garud category is an exciting challenge where teams
                design and program their own guardian robots to protect their
                towers in a thrilling arena! Each team builds a robot to guard
                their tower while trying to challenge and disrupt the opponent
                team's defense. The objective is to keep your tower safe from
                the opposing team's robot while attempting to disrupt their
                defense and score points. Robots can be autonomous, manually
                controlled, or a combination of both, allowing teams to showcase
                their creativity and technical skills. This fun and dynamic
                challenge inspires young minds to think critically, work
                together, and apply engineering principles in a competitive yet
                enjoyable setting, making the Tower Defense Challenge a favorite
                among participants!
                <br />
                <br />
                <Typography
                  variant={"h1"}
                  sx={{
                    textAlign: "start",
                    fontSize: { xs: "28px" },
                    fontWeight: "500",
                    color: "#595c61",
                    marginTop: "20px",
                  }}
                >
                  Robo Guard category:
                </Typography>
                <Box
                  sx={{
                    height: "3px",
                    maxWidth: { xs: "100px", md: "150px" },
                    width: "100%",
                    backgroundColor: "#c11a83",
                    margin: "10px 0 0 0",
                  }}
                />
                <br />
                <span style={{ fontWeight: "700", color: "#c11a83" }}>
                  Age:
                </span>{" "}
                6 - 10
                <br />
                <span style={{ fontWeight: "700", color: "#c11a83" }}>
                  Team size:
                </span>{" "}
                2-5 people guided by a coach <br />
                <span style={{ fontWeight: "700", color: "#c11a83" }}>
                  Hardware:
                </span>{" "}
                The controllers, motors and sensors used can be from Wedo 0.2 or
                Spike essential kits only
                <br />
                <span style={{ fontWeight: "700", color: "#c11a83" }}>
                  Maximum robot size:
                </span>{" "}
                Max. 15 x 15cm
              </Typography>
              <br />
            </Grid>

            <Grid
              container
              spacing={2}
              sx={{
                marginTop: "20px",
                justifyContent: "center",
                marginLeft: "16px",
                marginBottom: "20px",
              }}
            >
              <Grid item xs={12} sm={6} md={3}>
                <Box>
                  <img
                    alt={"image"}
                    loading={"lazy"}
                    src={Gallery.gallery18}
                    style={{
                      width: "100%",
                      borderRadius: "10px",
                    }}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <Box>
                  <img
                    alt={"image"}
                    loading={"lazy"}
                    src={Gallery.gallery19}
                    style={{
                      width: "100%",
                      borderRadius: "10px",
                    }}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <Box>
                  <img
                    alt={"image"}
                    loading={"lazy"}
                    src={Gallery.gallery39}
                    style={{
                      width: "100%",
                      borderRadius: "10px",
                    }}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <Box>
                  <img
                    alt={"image"}
                    loading={"lazy"}
                    src={Gallery.gallery24}
                    style={{
                      width: "100%",
                      borderRadius: "10px",
                    }}
                  />
                </Box>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Box>
      <Box
        sx={{
          width: "100%",
          backgroundColor: "primary.main",
        }}
      >
        <Box
          sx={{
            width: "100%",
            padding: { xs: "50px 20px", sm: "50px 60px", lg: "50px 120px" },
            maxWidth: "1500px",
            margin: "auto",
          }}
        >
          <Typography
            variant={"h2"}
            sx={{
              textAlign: "center",
              fontSize: { xs: "40px" },
              fontWeight: "500",
              color: "secondary.main",
            }}
          >
            Our All Categories
          </Typography>
          <Box
            sx={{
              height: "3px",
              maxWidth: { xs: "100px", md: "150px" },
              width: "100%",
              backgroundColor: "secondary.main",
              margin: "10px auto 0 auto",
            }}
          />
          <Grid
            container
            spacing={2}
            sx={{ marginTop: "50px", justifyContent: "center" }}
          >
            {categoriesData
              ?.filter((category) => category.title !== "Sumo")
              ?.map((item) => (
                <Grid item xs={12} sm={6} md={3}>
                  <Box
                    sx={{
                      backgroundImage: `linear-gradient(0deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.5)), url(${item.image})`,
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                      backgroundRepeat: "no-repeat",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "flex-end",
                      width: "100%",
                      minHeight: { xs: "200px", sm: "250px" },
                      borderRadius: "10px",
                    }}
                  >
                    <Typography
                      variant={"h3"}
                      sx={{
                        textAlign: "center",
                        fontSize: "35px",
                        fontWeight: "500",
                        color: "secondary.main",
                      }}
                    >
                      {item.title}
                    </Typography>
                    <Box
                      sx={{
                        height: "3px",
                        maxWidth: "90px",
                        width: "100%",
                        backgroundColor: "secondary.main",
                        margin: "5px auto 5px auto",
                      }}
                    />
                    <Box
                      sx={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <Button
                        variant="contained"
                        color="secondary"
                        sx={{
                          fontSize: "13px",
                          fontWeight: "600",
                          color: "primary.main",
                          width: "fit-content",
                          margin: { xs: "5px 0 20px 0", sm: "10px 0 40px 0" },
                        }}
                        onClick={() => navigate(item.link)}
                      >
                        view
                      </Button>
                    </Box>
                  </Box>
                </Grid>
              ))}
          </Grid>
        </Box>
      </Box>
    </>
  );
};
export default SumoTemplate;
