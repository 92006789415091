import { Box, Button, Grid, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { Images } from "../../../../helpers/Images";
import { categoriesData } from "../../../../helpers/Arrays";
import { Gallery } from "../../../../helpers/gallery";

const FollowlineTemplate = () => {
  const navigate = useNavigate();
  return (
    <>
      <Box
        sx={{
          width: "100%",
          padding: { xs: "50px 20px", sm: "50px 60px", lg: "50px 120px" },
          maxWidth: "1500px",
          margin: "auto",
        }}
      >
        {false ? (
          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography
              variant={"h1"}
              sx={{
                textAlign: "start",
                fontSize: { xs: "40px", sm: "70px" },
                fontWeight: "500",
                color: "primary.darker",
                margin: "100px auto 0 auto",
              }}
            >
              COMING SOON
            </Typography>
            <Box
              sx={{
                height: "3px",
                maxWidth: { xs: "100px", md: "300px" },
                width: "100%",
                backgroundColor: "primary.main",
                margin: "5px auto 5px auto",
              }}
            />
            <Typography
              variant={"h2"}
              sx={{
                textAlign: "start",
                fontSize: { xs: "40px", sm: "40px" },
                fontWeight: "500",
                color: "primary.darker",
                margin: "5px auto 100px auto",
              }}
            >
              WeDo Challenge
            </Typography>
          </Box>
        ) : (
          <Grid
            spacing={4}
            alignItems={"start"}
            justifyContent={"space-between"}
          >
            <Grid item xs={12} lg={7}>
              <Typography
                variant={"h1"}
                sx={{
                  textAlign: "center",
                  fontSize: { xs: "40px", sm: "55px" },
                  fontWeight: "500",
                  color: "#595c61",
                }}
              >
                Follow Line
              </Typography>
              <Box
                sx={{
                  height: "3px",
                  maxWidth: { xs: "100px", md: "150px" },
                  width: "100%",
                  backgroundColor: "#c11a83",
                  margin: "auto",
                  marginTop: "5px",
                }}
              />
              <Grid item xs={12} lg={4} sx={{ textAlign: "center" }}>
                <br />
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                 {/* <a
                    download
                    href={
                      "https://drive.google.com/uc?export=download&id=1ja0SuhGNftsqJ7kJpgl5jHpoM7LX_A58"
                    }
                  > 
                    <Button
                      variant="contained"
                      color="primary"
                      sx={{
                        fontSize: "17px",
                        margin: "5px 0",
                        fontWeight: "600",
                        backgroundColor: "#c11a83",
                      }}
                    >
                      Download Detailed Rules (PDF)
                    </Button>
                  </a>
                  */}
                </Box>
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                </Box>
              </Grid>
              <Typography
                sx={{
                  textAlign: "left",
                  color: "primary.darker",
                  // maxWidth: "750px",
                  margin: "25px 0 10px 0",
                  fontSize: "17px",
                }}
              >
                <Typography
                  variant={"h1"}
                  sx={{
                    textAlign: "start",
                    fontSize: { xs: "30px" },
                    fontWeight: "500",
                    color: "#595c61",
                    marginTop: "50px",
                    marginBottom: "10px",
                  }}
                >
                  Brief:
                </Typography>
                In this category, teams will design and program robots to autonomously navigate a line track from the starting point to the end of the line. 
                The robots must use sensors, such as Colour sensors or IR sensors, to detect and follow the line accurately. 
                The challenge is a race between two teams navigating the same line on parallel tracks, 
                adding excitement and competition. Creativity in robot design and coding strategies is highly encouraged as each team strives for a smooth and efficient run.  
                Teams that navigate the line faster will emerge as winners. This category is an excellent way to explore programming logic and sensor integration while fostering teamwork, 
                strategic thinking, and competitive spirit!

                <br />
                <br />
                <Typography
                  variant={"h1"}
                  sx={{
                    textAlign: "start",
                    fontSize: { xs: "28px" },
                    fontWeight: "500",
                    color: "#595c61",
                    marginTop: "20px",
                  }}
                >
                  FolowLine category:
                </Typography>
                <Box
                  sx={{
                    height: "3px",
                    maxWidth: { xs: "100px", md: "150px" },
                    width: "100%",
                    backgroundColor: "#c11a83",
                    margin: "10px 0 0 0",
                  }}
                />
                <br />
                <span style={{ fontWeight: "700", color: "#c11a83" }}>
                  Age:
                </span>{" "}
                6 - 10
                <br />
                <span style={{ fontWeight: "700", color: "#c11a83" }}>
                  Team size:
                </span>{" "}
                2-5 people guided by a coach <br />
                <span style={{ fontWeight: "700", color: "#c11a83" }}>
                  Hardware:
                </span>{" "}
                The controllers, motors and sensors used can be from LEGO education products or any tool Set
                <br />
                <span style={{ fontWeight: "700", color: "#c11a83" }}>
                Maximum robot size:
                </span>{" "}
                Max. 20 x 20 x 25 cm <br />
        
              </Typography>
              <br />
            </Grid>
            <Grid item xs={12} lg={4} sx={{ textAlign: "center" }}>
              <br />
            </Grid>
            <Grid
              container
              spacing={2}
              sx={{
                marginTop: "20px",
                justifyContent: "center",
                marginLeft: "16px",
                marginBottom: "20px",
              }}
            >
              <Grid item xs={12} sm={6} md={3}>
                <Box>
                  <img
                    alt={"image"}
                    loading={"lazy"}
                    src={Gallery.gallery6}
                    style={{
                      width: "100%",
                      borderRadius: "10px",
                    }}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <Box>
                  <img
                    alt={"image"}
                    loading={"lazy"}
                    src={Gallery.gallery10}
                    style={{
                      width: "100%",
                      borderRadius: "10px",
                    }}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <Box>
                  <img
                    alt={"image"}
                    loading={"lazy"}
                    src={Gallery.gallery9}
                    style={{
                      width: "100%",
                      borderRadius: "10px",
                    }}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <Box>
                  <img
                    alt={"image"}
                    loading={"lazy"}
                    src={Gallery.gallery11}
                    style={{
                      width: "100%",
                      borderRadius: "10px",
                    }}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <Box>
                  <img
                    alt={"image"}
                    loading={"lazy"}
                    src={Gallery.gallery12}
                    style={{
                      width: "100%",
                      borderRadius: "10px",
                    }}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <Box>
                  <img
                    alt={"image"}
                    loading={"lazy"}
                    src={Gallery.gallery42}
                    style={{
                      width: "100%",
                      borderRadius: "10px",
                    }}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <Box>
                  <img
                    alt={"image"}
                    loading={"lazy"}
                    src={Gallery.gallery46}
                    style={{
                      width: "100%",
                      borderRadius: "10px",
                    }}
                  />
                </Box>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Box>
      <Box
        sx={{
          width: "100%",
          backgroundColor: "primary.main",
        }}
      >
        <Box
          sx={{
            width: "100%",
            padding: { xs: "50px 20px", sm: "50px 60px", lg: "50px 120px" },
            maxWidth: "1500px",
            margin: "auto",
          }}
        >
          <Typography
            variant={"h2"}
            sx={{
              textAlign: "center",
              fontSize: { xs: "40px" },
              fontWeight: "500",
              color: "secondary.main",
            }}
          >
            Our All Categories
          </Typography>
          <Box
            sx={{
              height: "3px",
              maxWidth: { xs: "100px", md: "150px" },
              width: "100%",
              backgroundColor: "secondary.main",
              margin: "10px auto 0 auto",
            }}
          />
          <Grid
            container
            spacing={2}
            sx={{ marginTop: "50px", justifyContent: "center" }}
          >
            {categoriesData
              ?.filter((category) => category.title !== "Follow Line")
              ?.map((item) => (
                <Grid item xs={12} sm={6} md={3}>
                  <Box
                    sx={{
                      backgroundImage: `linear-gradient(0deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.5)), url(${item.image})`,
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                      backgroundRepeat: "no-repeat",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "flex-end",
                      width: "100%",
                      minHeight: { xs: "200px", sm: "250px" },
                      borderRadius: "10px",
                    }}
                  >
                    <Typography
                      variant={"h3"}
                      sx={{
                        textAlign: "center",
                        fontSize: "35px",
                        fontWeight: "500",
                        color: "secondary.main",
                      }}
                    >
                      {item.title}
                    </Typography>
                    <Box
                      sx={{
                        height: "3px",
                        maxWidth: "90px",
                        width: "100%",
                        backgroundColor: "secondary.main",
                        margin: "5px auto 5px auto",
                      }}
                    />
                    <Box
                      sx={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <Button
                        variant="contained"
                        color="secondary"
                        sx={{
                          fontSize: "13px",
                          fontWeight: "600",
                          color: "primary.main",
                          width: "fit-content",
                          margin: { xs: "5px 0 20px 0", sm: "10px 0 40px 0" },
                        }}
                        onClick={() => navigate(item.link)}
                      >
                        view
                      </Button>
                    </Box>
                  </Box>
                </Grid>
              ))}
          </Grid>
        </Box>
      </Box>
    </>
  );
};

export default FollowlineTemplate;