/* -- -- -- Logo -- -- -- */

import NavBack from "../assets/nav.png";
import FacebookIconCircle from "../assets/facebook.jpg";
import WhatsAppIconCircle from "../assets/whatsapp.jpg";
import InstagramIconCircle from "../assets/instagram.png";
import YoutubeIconCircle from "../assets/youtube.png";
import govern from "../assets/city.png";
import team from "../assets/team.png";
import coach from "../assets/leadership.png";
import category from "../assets/category.png";
import academy from "../assets/headquarter.png";
import student from "../assets/man.png";
import menu from "../assets/menu.png";
import nextArrow from "../assets/next.png";
import prevArrow from "../assets/previous.png";
import wedo from "../assets/wedo.jpg";
import wedo2 from "../assets/wedo2.jpg";
import call from "../assets/phone-call.png";
import community from "../assets/community.png";
import chat from "../assets/chatting.png";
import quote from "../assets/quote.png";
import gold from "../assets/gold.jpg";
import website from "../assets/website.png";
import mail from "../assets/mail.webp";
import codeLogoHor from "../assets/WEDO1.png";
import wedoLogo from "../assets/wedoLogo.png";
import rec from "../assets/rec.jpg";
import wedocover from "../assets/wedocover.png";
import sumo from "../assets/sumo.jpg";
import follow from "../assets/follow.jpg";
import sponsor1 from "../assets/Sponsers/sponser (1).png";
import sponsor2 from "../assets/Sponsers/sponser (2).png";
import sponsor3 from "../assets/Sponsers/sponser (3).png";
import sponsor4 from "../assets/Sponsers/sponser (4).png";
import sponsor5 from "../assets/Sponsers/sponser (5).png";
import sponsor6 from "../assets/Sponsers/sponser (6).webp";
import sponsor7 from "../assets/Sponsers/sponser (7).jpeg";
import sponsor8 from "../assets/Sponsers/sponser (8).png";
import pulsonic from "../assets/Full-White.png";

import academy1 from "../assets/academy/academy_1.jpeg";
import academy2 from "../assets/academy/academy_2.jpeg";
import academy3 from "../assets/academy/academy_3.jpeg";
import academy4 from "../assets/academy/academy_4.jpeg";
import academy5 from "../assets/academy/academy_5.jpeg";
import academy6 from "../assets/academy/academy_6.jpeg";
import academy7 from "../assets/academy/academy_7.jpeg";
import academy8 from "../assets/academy/academy_8.jpeg";
import academy9 from "../assets/academy/academy_9.jpeg";
import academy10 from "../assets/academy/academy_10.jpeg";
import academy11 from "../assets/academy/academy_11.jpeg";
import academy12 from "../assets/academy/academy_12.jpg";
import academy13 from "../assets/academy/academy_13.png";
import academy14 from "../assets/academy/academy_14.png";
import academy15 from "../assets/academy/academy_15.png";
import academy16 from "../assets/academy/academy_16.PNG";
import academy17 from "../assets/academy/academy_17.png";
import academy18 from "../assets/academy/academy_18.JPG";
import academy19 from "../assets/academy/academy_19.png";
import academy20 from "../assets/academy/academy_20.jpg";
import academy21 from "../assets/academy/academy_21.png";
import academy22 from "../assets/academy/academy_22.jpg";
import academy23 from "../assets/academy/academy_23.png";
import academy24 from "../assets/academy/academy_24.jpg";
import academy25 from "../assets/academy/academy_25.png";
import academy26 from "../assets/academy/academy_26.jpg";
import academy27 from "../assets/academy/academy_27.png";
import academy28 from "../assets/academy/academy_28.jpg";
import academy29 from "../assets/academy/academy_29.png";
import academy30 from "../assets/academy/academy_30.jpg";
import academy31 from "../assets/academy/academy_31.png";
import academy32 from "../assets/academy/academy_32.jpg";
import academy33 from "../assets/academy/academy_33.png";
import academy34 from "../assets/academy/academy_34.jpg";
import academy35 from "../assets/academy/academy_35.png";
import academy36 from "../assets/academy/academy_36.jpg";
import academy37 from "../assets/academy/academy_37.png";
import academy38 from "../assets/academy/academy_38.jpg";
import academy39 from "../assets/academy/academy_39.PNG";
import academy40 from "../assets/academy/academy_40.PNG";
import academy41 from "../assets/academy/academy_41.png";
import academy42 from "../assets/academy/academy_42.png";

export const Images = {
  call,
  community,
  mail,
  chat,
  wedo,
  wedo2,
  NavBack,
  FacebookIconCircle,
  WhatsAppIconCircle,
  InstagramIconCircle,
  YoutubeIconCircle,
  govern,
  team,
  coach,
  academy,
  category,
  student,
  menu,
  nextArrow,
  prevArrow,
  quote,
  gold,
  codeLogoHor,
  wedoLogo,
  rec,
  wedocover,
  sumo,
  website,
  follow,
  sponsor1,
  sponsor2,
  sponsor3,
  sponsor4,
  sponsor5,
  sponsor6,
  sponsor7,
  sponsor8,
  pulsonic,
};

export const AcademiesLogo = {
  academy1,
  academy2,
  academy3,
  academy4,
  academy5,
  academy6,
  academy7,
  academy8,
  academy9,
  academy10,
  academy11,
  academy12,
  academy13,
  academy14,
  academy15,
  academy16,
  academy17,
  academy18,
  academy19,
  academy20,
  academy21,
  academy22,
  academy23,
  academy24,
  academy25,
  academy26,
  academy27,
  academy28,
  academy29,
  academy30,
  academy31,
  academy32,
  academy33,
  academy34,
  academy35,
  academy36,
  academy37,
  academy38,
  academy39,
  academy40,
  academy41,
  academy42,
};
