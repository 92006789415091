import React from "react";
import "./LogoCarousel.css";
import { Typography, Grid } from "@mui/material";
import { Box } from "@mui/system";
import { Images } from "../../../../helpers/Images";

function LogoCarouse() {
  return (
    <Box
      sx={{
        width: "100%",
        padding: {
          xs: "50px 20px",
          sm: "50px 60px",
          lg: "70px 120px",
        },
        backgroundColor: "secondary.main",
        maxWidth: "1500px",
        margin: "auto",
      }}
    >
      <Typography
        variant={"h2"}
        sx={{
          textAlign: "center",
          fontSize: "35px",
          fontWeight: "500",
          color: "primary.darker",
        }}
      >
        We Do Challenge Sponsors
      </Typography>
      <Box
        sx={{
          height: "3px",
          width: "150px",
          backgroundColor: "primary.light",
          margin: "10px auto",
        }}
      />
      <Grid container sx={{ justifyContent: "center", marginTop: "20px" }}>
        <Grid
          item
          xs={6}
          sm={3}
          md={3}
          sx={{ display: "flex", order: { xs: "2", sm: "1" } }}
        >
          <img
            src={Images.sponsor6}
            alt=""
            loading="lazy"
            style={{ height: "100px", margin: "auto" }}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={4}
          md={3}
          lg={2}
          sx={{ display: "flex", order: { xs: "1", sm: "2" }, margin: "5px" }}
        >
          <img
            src={Images.sponsor1}
            alt=""
            loading="lazy"
            style={{ height: "150px", margin: "auto" }}
          />
        </Grid>
        <Grid
          item
          xs={6}
          sm={3}
          md={3}
          sx={{ display: "flex", order: { xs: "3", sm: "3" } }}
        >
          <img
            src={Images.sponsor2}
            alt=""
            loading="lazy"
            style={{ height: "60px", margin: "auto" }}
          />
        </Grid>
      </Grid>
      <Grid container sx={{ justifyContent: "center", marginTop: "0px" }}>
        <Grid item xs={6} sm={3} md={3} lg={3} sx={{ display: "flex" }}>
          <img
            src={Images.sponsor3}
            alt=""
            loading="lazy"
            style={{ height: "80px", margin: "auto" }}
          />
        </Grid>
        <Grid item xs={6} sm={3} md={3} lg={3} sx={{ display: "flex" }}>
          <img
            src={Images.sponsor4}
            alt=""
            loading="lazy"
            style={{ height: "80px", margin: "auto" }}
          />
        </Grid>
        <Grid item xs={6} sm={3} md={3} lg={3} sx={{ display: "flex" }}>
          <img
            src={Images.sponsor8}
            alt=""
            loading="lazy"
            style={{ height: "45px", margin: "auto" }}
          />
        </Grid>
        
        <Grid item xs={6} sm={3} md={3} lg={3} sx={{ display: "flex" }}>
          <img
            src={Images.sponsor5}
            alt=""
            loading="lazy"
            style={{ height: "80px", margin: "auto" }}
          />
        </Grid>
       
      </Grid>
      <Typography
        variant={"h2"}
        sx={{
          textAlign: "center",
          fontSize: "35px",
          fontWeight: "500",
          color: "primary.darker",
        }}
      >
        Success Partners
      </Typography>
      <Box
        sx={{
          height: "3px",
          width: "150px",
          backgroundColor: "primary.light",
          margin: "10px auto",
        }}
      />
      <div className="LogoContainer">
        <div className="logo">
          <img
            src={require("../../../../assets/AcademiesLogos/2.png")}
            alt=""
            loading="lazy"
          />
          <img
            src={require("../../../../assets/AcademiesLogos/3.jpeg")}
            alt=""
            loading="lazy"
          />
          <img
            src={require("../../../../assets/AcademiesLogos/4.jpeg")}
            alt=""
            loading="lazy"
          />
          <img
            src={require("../../../../assets/AcademiesLogos/5.jpg")}
            alt=""
            loading="lazy"
          />
          <img
            src={require("../../../../assets/AcademiesLogos/6.png")}
            alt=""
            loading="lazy"
          />
          <img
            src={require("../../../../assets/AcademiesLogos/7.png")}
            alt=""
            loading="lazy"
          />
          <img
            src={require("../../../../assets/AcademiesLogos/8.jpg")}
            alt=""
            loading="lazy"
          />
          <img
            src={require("../../../../assets/AcademiesLogos/9.png")}
            alt=""
            loading="lazy"
          />
          <img
            src={require("../../../../assets/AcademiesLogos/10.jpeg")}
            alt=""
            loading="lazy"
          />
          <img
            src={require("../../../../assets/AcademiesLogos/11.png")}
            alt=""
            loading="lazy"
          />
          <img
            src={require("../../../../assets/AcademiesLogos/12.png")}
            alt=""
            loading="lazy"
          />
          <img
            src={require("../../../../assets/AcademiesLogos/13.jpeg")}
            alt=""
            loading="lazy"
          />
          <img
            src={require("../../../../assets/AcademiesLogos/14.png")}
            alt=""
            loading="lazy"
          />
          <img
            src={require("../../../../assets/AcademiesLogos/15.jpg")}
            alt=""
            loading="lazy"
          />
          <img
            src={require("../../../../assets/AcademiesLogos/16.jpg")}
            alt=""
            loading="lazy"
          />
          <img
            src={require("../../../../assets/AcademiesLogos/17.jpg")}
            alt=""
            loading="lazy"
          />
          <img
            src={require("../../../../assets/AcademiesLogos/18.png")}
            alt=""
            loading="lazy"
          />
          <img
            src={require("../../../../assets/AcademiesLogos/19.png")}
            alt=""
            loading="lazy"
          />
          <img
            src={require("../../../../assets/AcademiesLogos/20.jpeg")}
            alt=""
            loading="lazy"
          />
          <img
            src={require("../../../../assets/AcademiesLogos/21.png")}
            alt=""
            loading="lazy"
          />
          <img
            src={require("../../../../assets/AcademiesLogos/22.jpeg")}
            alt=""
            loading="lazy"
          />
          <img
            src={require("../../../../assets/AcademiesLogos/23.jpeg")}
            alt=""
            loading="lazy"
          />
          <img
            src={require("../../../../assets/AcademiesLogos/24.png")}
            alt=""
            loading="lazy"
          />
          <img
            src={require("../../../../assets/AcademiesLogos/25.jpg")}
            alt=""
            loading="lazy"
          />
          <img
            src={require("../../../../assets/AcademiesLogos/26.jpeg")}
            alt=""
            loading="lazy"
          />
          <img
            src={require("../../../../assets/AcademiesLogos/27.jpg")}
            alt=""
            loading="lazy"
          />
          <img
            src={require("../../../../assets/AcademiesLogos/28.png")}
            alt=""
            loading="lazy"
          />
          <img
            src={require("../../../../assets/AcademiesLogos/29.png")}
            alt=""
            loading="lazy"
          />
          <img
            src={require("../../../../assets/AcademiesLogos/30.jpeg")}
            alt=""
            loading="lazy"
          />
          <img
            src={require("../../../../assets/AcademiesLogos/31.jpeg")}
            alt=""
            loading="lazy"
          />
          <img
            src={require("../../../../assets/AcademiesLogos/32.jpg")}
            alt=""
            loading="lazy"
          />
          <img
            src={require("../../../../assets/AcademiesLogos/33.png")}
            alt=""
            loading="lazy"
          />
          <img
            src={require("../../../../assets/AcademiesLogos/34.jpeg")}
            alt=""
            loading="lazy"
          />
          <img
            src={require("../../../../assets/AcademiesLogos/35.jpg")}
            alt=""
            loading="lazy"
          />
          <img
            src={require("../../../../assets/AcademiesLogos/36.png")}
            alt=""
            loading="lazy"
          />
          <img
            src={require("../../../../assets/AcademiesLogos/37.jpg")}
            alt=""
            loading="lazy"
          />
          <img
            src={require("../../../../assets/AcademiesLogos/38.png")}
            alt=""
            loading="lazy"
          />
          <img
            src={require("../../../../assets/AcademiesLogos/39.jpg")}
            alt=""
            loading="lazy"
          />
          <img
            src={require("../../../../assets/AcademiesLogos/40.png")}
            alt=""
            loading="lazy"
          />
          <img
            src={require("../../../../assets/AcademiesLogos/41.png")}
            alt=""
            loading="lazy"
          />
          <img
            src={require("../../../../assets/AcademiesLogos/42.png")}
            alt=""
            loading="lazy"
          />
        </div>
        <div className="logo">
          <img
            src={require("../../../../assets/AcademiesLogos/2.png")}
            alt=""
            loading="lazy"
          />
          <img
            src={require("../../../../assets/AcademiesLogos/3.jpeg")}
            alt=""
            loading="lazy"
          />
          <img
            src={require("../../../../assets/AcademiesLogos/4.jpeg")}
            alt=""
            loading="lazy"
          />
          <img
            src={require("../../../../assets/AcademiesLogos/5.jpg")}
            alt=""
            loading="lazy"
          />
          <img
            src={require("../../../../assets/AcademiesLogos/6.png")}
            alt=""
            loading="lazy"
          />
          <img
            src={require("../../../../assets/AcademiesLogos/7.png")}
            alt=""
            loading="lazy"
          />
          <img
            src={require("../../../../assets/AcademiesLogos/8.jpg")}
            alt=""
            loading="lazy"
          />
          <img
            src={require("../../../../assets/AcademiesLogos/9.png")}
            alt=""
            loading="lazy"
          />
          <img
            src={require("../../../../assets/AcademiesLogos/10.jpeg")}
            alt=""
            loading="lazy"
          />
          <img
            src={require("../../../../assets/AcademiesLogos/11.png")}
            alt=""
            loading="lazy"
          />
          <img
            src={require("../../../../assets/AcademiesLogos/12.png")}
            alt=""
            loading="lazy"
          />
          <img
            src={require("../../../../assets/AcademiesLogos/13.jpeg")}
            alt=""
            loading="lazy"
          />
          <img
            src={require("../../../../assets/AcademiesLogos/14.png")}
            alt=""
            loading="lazy"
          />
          <img
            src={require("../../../../assets/AcademiesLogos/15.jpg")}
            alt=""
            loading="lazy"
          />
          <img
            src={require("../../../../assets/AcademiesLogos/16.jpg")}
            alt=""
            loading="lazy"
          />
          <img
            src={require("../../../../assets/AcademiesLogos/17.jpg")}
            alt=""
            loading="lazy"
          />
          <img
            src={require("../../../../assets/AcademiesLogos/18.png")}
            alt=""
            loading="lazy"
          />
          <img
            src={require("../../../../assets/AcademiesLogos/19.png")}
            alt=""
            loading="lazy"
          />
          <img
            src={require("../../../../assets/AcademiesLogos/20.jpeg")}
            alt=""
            loading="lazy"
          />
          <img
            src={require("../../../../assets/AcademiesLogos/21.png")}
            alt=""
            loading="lazy"
          />
          <img
            src={require("../../../../assets/AcademiesLogos/22.jpeg")}
            alt=""
            loading="lazy"
          />
          <img
            src={require("../../../../assets/AcademiesLogos/23.jpeg")}
            alt=""
            loading="lazy"
          />
          <img
            src={require("../../../../assets/AcademiesLogos/24.png")}
            alt=""
            loading="lazy"
          />
          <img
            src={require("../../../../assets/AcademiesLogos/25.jpg")}
            alt=""
            loading="lazy"
          />
          <img
            src={require("../../../../assets/AcademiesLogos/26.jpeg")}
            alt=""
            loading="lazy"
          />
          <img
            src={require("../../../../assets/AcademiesLogos/27.jpg")}
            alt=""
            loading="lazy"
          />
          <img
            src={require("../../../../assets/AcademiesLogos/28.png")}
            alt=""
            loading="lazy"
          />
          <img
            src={require("../../../../assets/AcademiesLogos/29.png")}
            alt=""
            loading="lazy"
          />
          <img
            src={require("../../../../assets/AcademiesLogos/30.jpeg")}
            alt=""
            loading="lazy"
          />
          <img
            src={require("../../../../assets/AcademiesLogos/31.jpeg")}
            alt=""
            loading="lazy"
          />
          <img
            src={require("../../../../assets/AcademiesLogos/32.jpg")}
            alt=""
            loading="lazy"
          />
          <img
            src={require("../../../../assets/AcademiesLogos/33.png")}
            alt=""
            loading="lazy"
          />
          <img
            src={require("../../../../assets/AcademiesLogos/34.jpeg")}
            alt=""
            loading="lazy"
          />
          <img
            src={require("../../../../assets/AcademiesLogos/35.jpg")}
            alt=""
            loading="lazy"
          />
          <img
            src={require("../../../../assets/AcademiesLogos/36.png")}
            alt=""
            loading="lazy"
          />
          <img
            src={require("../../../../assets/AcademiesLogos/37.jpg")}
            alt=""
            loading="lazy"
          />
          <img
            src={require("../../../../assets/AcademiesLogos/38.png")}
            alt=""
            loading="lazy"
          />
          <img
            src={require("../../../../assets/AcademiesLogos/39.jpg")}
            alt=""
            loading="lazy"
          />
          <img
            src={require("../../../../assets/AcademiesLogos/40.png")}
            alt=""
            loading="lazy"
          />
          <img
            src={require("../../../../assets/AcademiesLogos/41.png")}
            alt=""
            loading="lazy"
          />
          <img
            src={require("../../../../assets/AcademiesLogos/42.png")}
            alt=""
            loading="lazy"
          />
        </div>
        <Typography
          variant={"h2"}
          sx={{
            textAlign: "center",
            fontSize: "35px",
            fontWeight: "500",
            color: "primary.darker",
          }}
        >
          Sponsors
        </Typography>
        <Box
          sx={{
            height: "3px",
            width: "150px",
            backgroundColor: "primary.light",
            margin: "10px auto",
          }}
        />
      </div>
    </Box>
  );
}

export default LogoCarouse;
