import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import "./Numbers.css";
import { Images } from "../../../../helpers/Images";

const Numbers = () => {
  return (
    <Box
      sx={{
        width: "100%",
        padding: { xs: "30px 20px", sm: "30px 60px", lg: "5px 120px 80px" },
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        backgroundColor: "#fff",
        maxWidth: "1500px",
        margin: "auto",
      }}
    >
      <Typography
        variant={"h2"}
        sx={{
          textAlign: "center",
          fontSize: "35px",
          fontWeight: "500",
          color: "primary.darker",
        }}
      >
        We Do Challenge Numbers
      </Typography>
      <Box
        sx={{
          height: "3px",
          width: "150px",
          backgroundColor: "primary.main",
          margin: "auto",
          marginTop: "10px",
        }}
      />
      <Typography
        variant={"p"}
        sx={{
          textAlign: "center",
          color: "primary.darker",
          maxWidth: "900px",
          margin: "30px auto 20px auto",
          fontSize: "17px",
        }}
      >
        From the start in 2020, WeDoChallenge® has grown rapidly. Each year, it has expanded to include more organisations and reach more children and young people. This growth reflects its dedication to empowering the next generation. By offering new opportunities and engaging more participants, it continues to make a positive impact on communities.
      </Typography>
      <Grid
        container
        justifyContent={"center"}
        sx={{ maxWidth: "1200px", margin: "auto" }}
      >
        {[
          { image: Images.student, number: "1710+", title: "Student" },
          { image: Images.team, number: "342+", title: "Team" },
          { image: Images.govern, number: "21+", title: "Governorate" },
          { image: Images.academy, number: "50+", title: "Academy/School" },
          { image: Images.category, number: "11", title: "Category" },
        ].map((item, i) => (
          <Grid
            item
            xs={6}
            sm={6}
            md={4}
            lg={4}
            key={i}
            sx={{ justifyContent: "center" }}
          >
            <Box
              sx={{
                border: "3px solid",
                borderColor: "primary.main",
                borderRadius: "50%",
                padding: { xs: "20px 10px", md: "40px 20px" },
                textAlign: "center",
                maxWidth: { xs: "150px", md: "250px" },
                margin: { xs: "10px auto", md: "10px auto" },
              }}
            >
              <img
                alt={i}
                src={item.image}
                className="circle"
                style={{
                  filter:
                    "invert(22%) sepia(52%) saturate(4198%) hue-rotate(304deg) brightness(84%) contrast(99%)",
                }}
              />
              <Typography
                variant={"h3"}
                sx={{
                  textAlign: "center",
                  fontSize: { xs: "30px", md: "45px" },
                  fontWeight: "500",
                  color: "primary.darker",
                }}
              >
                {item?.number}
              </Typography>
              <Typography
                variant={"h4"}
                sx={{
                  textAlign: "center",
                  fontSize: { xs: "13px", md: "20px" },
                  fontWeight: "500",
                  color: "primary.main",
                }}
              >
                {item?.title}
              </Typography>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default Numbers;

// RobotChallenge is one of the biggest competitions for self-made, autonomous and mobile robots worldw
