import { Box, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { Images } from "../../../helpers/Images";
import "./Footer.css";
import { FaFacebook, FaLinkedin, FaTiktok } from "react-icons/fa";
import { FaWhatsapp } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { FaYoutube } from "react-icons/fa";

const Footer = () => {
  const navigate = useNavigate();
  return (
    <Box
      sx={{
        width: "100%",
        backgroundColor: "#414141",
      }}
    >
      <Box
        sx={{
          width: "100%",
          padding: { xs: "20px 20px", sm: "20px 60px", lg: "20px 120px" },
          maxWidth: "1500px",
          margin: "auto",
        }}
      >
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <img
            style={{ height: "50px", cursor: "pointer" }}
            src={Images.codeLogoHor}
            alt="We Do Challenge Championship Logo"
            onClick={() => navigate("/")}
          />
          <div
            style={{ display: "flex", alignItems: "center", height: "60px" }}
          >
            <Typography
              variant={"h6"}
              sx={{
                textAlign: "center",
                fontWeight: "200",
                color: "secondary.main",
                fontSize: "15px",
                marginRight: "5px",
              }}
            >
              Stay in touch
            </Typography>
            <a
              target="_blank"
              href="https://www.facebook.com/WedoChallengeEgy?mibextid=ZbWKwL"
            >
              <FaFacebook
                className="SocialFooterIcon facebook"
                onClick={() => navigate("/")}
              />
            </a>
            <a
              target="_blank"
              href="https://www.linkedin.com/company/we-do-challenge/"
            >
              <FaLinkedin
                className="SocialFooterIcon linkedin"
                onClick={() => navigate("/")}
              />
            </a>
            <a target="_blank" href="https://wa.me/+201022758877">
              <FaWhatsapp
                className="SocialFooterIcon whatsapp"
                onClick={() => navigate("/")}
              />
            </a>
            <a
              target="_blank"
              href="https://www.instagram.com/wedochallengeegy/"
            >
              <FaInstagram
                className="SocialFooterIcon instagram"
                onClick={() => navigate("/")}
              />
            </a>
            {/* <a
              target="_blank"
              href="https://www.tiktok.com/@wedo_challenge?_t=8sgG40At7nc&_r=1"
            >
              <FaTiktok
                className="SocialFooterIcon tictok"
                onClick={() => navigate("/")}
              />
            </a> */}
          </div>
        </Box>
        <Box sx={{
          borderTop: "1px solid #fff", marginTop: "20px",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          paddingTop: "5px",
          flexDirection: { xs: "column", md: "row" }
        }}>
          <Typography
            variant={"h6"}
            sx={{
              textAlign: "center",
              fontWeight: "200",
              color: "secondary.main",
              fontSize: "15px",
            }}
          >
            WeDoChallenge© 2025 All Rights reserved.
          </Typography>
          <Typography
            variant={"h6"}
            sx={{
              textAlign: "center",
              fontWeight: "200",
              color: "secondary.main",
              fontSize: "15px",
              display: "flex",
              alignItems: "center"
            }}
          >
            Powered by <img src={Images.pulsonic} alt="pulsonic" style={{ height: "20px", marginLeft: "10px" }} />
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default Footer;

// RobotChallenge is one of the biggest competitions for self-made, autonomous and mobile robots worldw
