import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import { academiesData } from "../../helpers/Arrays";
import LogoCarouse from "../../Components/organisms/Home/LogoCarousel/LogoCarousel";

const AboutTemplate = () => {
  return (
    <Box
      sx={{
        width: "100%",
        padding: { xs: "50px 20px", sm: "50px 60px", lg: "50px 120px" },
        maxWidth: "1500px",
        margin: "auto",
      }}
    >
      <Typography
        variant={"h1"}
        sx={{
          textAlign: "center",
          fontSize: { xs: "40px", sm: "55px" },
          fontWeight: "500",
          color: "primary.darker",
        }}
      >
        About Us
      </Typography>
      <Box
        sx={{
          height: "3px",
          maxWidth: { xs: "100px", md: "150px" },
          width: "100%",
          backgroundColor: "primary.main",
          margin: "10px auto",
        }}
      />
      <Typography
        variant={"h2"}
        sx={{
          textAlign: "center",
          fontSize: "30px",
          fontWeight: "500",
          color: "primary.darker",
          marginTop: "50px",
        }}
      >
        Who are we
      </Typography>
      <Typography
        sx={{
          textAlign: "center",
          color: "primary.darker",
          width: "100%",
          maxWidth: "800px",
          margin: "auto",
          marginTop: "10px",
        }}
      >
        We Do Challenge is a unique global competition that ignites a passion
        for STEAM (Science, Technology, Engineering, Arts, and Mathematics) in
        young minds aged from 4 -10 years through real-world problem-solving.
        Each year, a new global challenge is presented, and children collaborate
        to design, build, and program robots to address that challenge. This
        fosters teamwork, critical thinking, and an understanding of how
        innovation can create a positive impact on the world, this
        problem-solving mindset, prepares the next generation of changemakers
      </Typography>
      <Box
        sx={{
          width: "100%",
          maxWidth: "1500px",
          margin: "auto",
        }}
      >  
        <Grid container spacing={5} sx={{ marginTop: "30px" }}>
          <Grid item xs={12} md={6}>
            <Box
              sx={{
                width: "100%",
                padding: "30px",
                backgroundColor: "primary.main",
                borderRadius: "10px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              
              <Typography
                variant={"h3"}
                sx={{
                  textAlign: "center",
                  fontSize: "50px",
                  fontWeight: "400",
                  color: "secondary.main",
                }}
              >
                    Vision
              </Typography>
              <Box
              sx={{
                height: "3px",
                maxWidth: { xs: "80px", md: "100px" },
                width: "100%",
                backgroundColor: "white",
                margin: "10px auto",
              }}
            />
              <Typography
                sx={{
                  textAlign: "center",
                  fontSize: "17px",
                  fontWeight: "300",
                  color: "secondary.main",
                  marginTop: "20px",
                  width: "80%",
                }}
              >
                We strive to empower young minds with thrilling, authentic real challenges, foster teamwork,
                 critical thinking,
                 and an understanding of how innovation can create a positive impact on the world. 
                 This problem-solving mindset, prepares the next generation of changemakers. 


              </Typography>
             
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "20px",
                }}
              >
        
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box
              sx={{
                width: "100%",
                backgroundColor: "primary.main",
                borderRadius: "10px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                height: "100%",
                justifyContent: "space-between",
              }}
            >
             
              <Typography
                variant={"h3"}
                sx={{
                  textAlign: "center",
                  fontSize: "50px",
                  fontWeight: "400",
                  color: "secondary.main",
                  marginTop: "25px",
                }}
              >
                Mission
              </Typography>
              <Box
              sx={{
                height: "3px",
                maxWidth: { xs: "80px", md: "100px" },
                width: "100%",
                backgroundColor: "white",
                margin: "10px auto",
              }}
            />
              <Typography
                sx={{
                  textAlign: "center",
                  fontSize: "17px",
                  fontWeight: "300",
                  color: "secondary.main",
                  marginTop: "20px",
                  width: "80%",
                }}
              >
                We aim to create an exceptional community where students aged 4 to 10 can demonstrate their skills and unlock their full potential.
                Through fostering a passion for technology, nurturing creativity, and promoting lifelong learning.
                We provide unmatched opportunities for growth, development, and enjoyable experiences, ensuring every participant thrives.

              </Typography>
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "20px",
                }}
              >    
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
           
 
      <LogoCarouse />
    </Box>
  );
};

export default AboutTemplate;
