import { AcademiesLogo, Images } from "./Images";

export const categoriesData = [
  {
    image: Images.wedo,
    title: "Robo Mission",
    link: "/competition/Robomission",
  },
  {
    image: Images.follow,
    title: "Follow Line",
    link: "/competition/Followline",
  },
  {
    image: Images.sumo,
    title: "Robo Guard",
    link: "/competition/Sumo",
  },
  {
    image: Images.rec,
    title: "Researchers",
    link: "/competition/Researchers",
  },
  {
    image: Images.wedo2,
    title: "Scientific Project",
    link: "/competition/Scientific",
  },
];

export const reviews = [
  {
    review: (
      <span>
        The idea that the championship is Egyptian and the beginning of
        large-scale participation by teams, along with the idea of involving
        Arab countries in the hosting, was at a very high level of cleanliness
        and preparation. The parking spaces were also very good. The
        presentation of the Palestinian cause and the video were very inspiring.
      </span>
    ),
    name: "Heba Mohamed ",
  },
  {
    review: (
      <span>
        The championship was very good, and the thing we liked the most was the
        encouragement from the judges to the kids while explaining their
        projects or playing on the ground.
      </span>
    ),
    name: "Rabab Agoua",
  },
  {
    review: (
      <span>
        The gifts were very nice and the group for communication between
        coaches, technical support was really appreciated .
      </span>
    ),
    name: "Jano Essam",
  },
  {
    review: (
      <span>
        The organization of the championship was excellent, and the atmosphere
        was amazing. The teams were well-prepared, and the support from the
        organizers was evident throughout the event. It was an unforgettable
        experience, and I truly appreciate the effort put into making it such a
        success.
      </span>
    ),
    name: "Ahmed El-Sayed",
  },

  {
    review: (
      <span>
        The event was a great opportunity for the kids to showcase their
        creativity and skills. The judges were encouraging and supportive,
        making the experience even more enjoyable for the participants. I was
        also impressed by how well the logistics were handled.
      </span>
    ),
    name: "Mona Hassan",
  },

  {
    review: (
      <span>
        Everything about the championship, from the venue to the technical
        support, was well thought out. The kids had a great time, and the
        competition was both fun and educational. I also loved how the judges
        were so involved and motivated the participants to do their best.
      </span>
    ),
    name: "Dalia Khalil",
  },
  {
    review: (
      <span>
        The organization team spirit was incredible, and the mentors provided
        invaluable guidance throughout the event. I'm proud of how the kids rose
        to the occasion and worked together.
      </span>
    ),
    name: "Tarek Farouk",
  },

  {
    review: (
      <span>
        The event was professionally organized, and the kids were so excited to
        participate. The competition itself was a great learning experience for
        everyone involved. It was wonderful to see so many teams working
        together towards a common goal.
      </span>
    ),
    name: "Youssef Ibrahim",
  },
];

export const arrayOfAcademies = [
  { name: "4My-Robot" },
  { name: "AA Robotics" },
  { name: "AAST teams" },
  { name: "ACA Robotics" },
  { name: "AI Academy" },
  { name: "Al Farouk Academy" },
  { name: "Al Safa Language School" },
  { name: "Almobbtakron" },
  { name: "Alpha Robot Academy" },
  { name: "American languages schools Shebin Elkoum" },
  { name: "Artifinity" },
  { name: "ASME Assuit" },
  { name: "Asdaa" },
  { name: "BareeQ Academy" },
  { name: "begining Academy" },
  { name: "Big Hero Academy" },
  { name: "Bricks Builder" },
  { name: "Child Home Academy" },
  { name: "CodeCraft" },
  { name: "CodeWave" },
  { name: "Creative Generation Academy" },
  { name: "Discovery Academy (Monofia)" },
  { name: "Discovery Academy (TAGAMOU3)" },
  { name: "ETS Academy" },
  { name: "Future Makers Academy" },
  { name: "FutureMinds Academy" },
  { name: "Futuristic Science Organization" },
  { name: "GENIUS CODER" },
  { name: "Genius Academy" },
  { name: "Genius Minds" },
  { name: "HB Tech Academy" },
  { name: "Helwan University Faculty of Engineering" },
  { name: "I Can Code Academy" },
  { name: "I.C academy" },
  { name: "Ibdaa Academy" },
  { name: "Innova STEM Education" },
  { name: "Innovation-hub" },
  { name: "IRON Teams" },
  { name: "IVY STEM International School" },
  { name: "Jupiter Academy" },
  { name: "Little Engineer Academy" },
  { name: "Little Engineer Center" },
  { name: "M&P Robotics" },
  { name: "Maven International School" },
  { name: "Mawaheb (El-Aasher)" },
  { name: "Mawaheb Academy (Maadi)" },
  { name: "Mawaheb Academy (Nasr city)" },
  { name: "Mawaheb Academy (Suez)" },
  { name: "MAY UNIVERSITY IN CAIRO" },
  { name: "Mega Robotics Academy" },
  { name: "Metanoia planet Academy" },
  { name: "MMS Academy" },
  { name: "Map Language School" },
  { name: "Mind Shine" },
  { name: "Noor Language School" },
  { name: "Robot Academy" },
  { name: "ROBOTIC-FOR-KIDS" },
  { name: "RoboDizer Academy" },
  { name: "Roboharvest Academy" },
  { name: "Robokids Academy" },
  { name: "Robotica Academy" },
  { name: "Robotix Academy" },
  { name: "Robotopia Academy (Fayoum)" },
  { name: "STS Academy" },
  { name: "STEM Zone Academy" },
  { name: "Sciences Academy" },
  { name: "Techware Academy" },
  { name: "Techno Square Academy" },
  { name: "Techno Titans Academy" },
  { name: "Technospace team" },
  { name: "Technology leaders (6 October)" },
  { name: "Technology leaders (Zayed)" },
  { name: "Treasures Academy Ganakles" },
  { name: "Treasures Academy sidi gaber" },
  { name: "Utopia Academy" },
  { name: "VBO TECH" },
  { name: "Willy Robotics Academy" },
  { name: "Wissen Academy" },
  { name: "X_robot Academy" },
  { name: "ZHUB Academy" },
];

export const academiesData = [
  { image: AcademiesLogo.academy1 },
  { image: AcademiesLogo.academy2 },
  { image: AcademiesLogo.academy3 },
  { image: AcademiesLogo.academy4 },
  { image: AcademiesLogo.academy5 },
  { image: AcademiesLogo.academy6 },
  { image: AcademiesLogo.academy7 },
  { image: AcademiesLogo.academy8 },
  { image: AcademiesLogo.academy9 },
  { image: AcademiesLogo.academy10 },
  { image: AcademiesLogo.academy11 },
  { image: AcademiesLogo.academy12 },
  { image: AcademiesLogo.academy13 },
  { image: AcademiesLogo.academy14 },
  { image: AcademiesLogo.academy15 },
  { image: AcademiesLogo.academy16 },
  { image: AcademiesLogo.academy17 },
  { image: AcademiesLogo.academy18 },
  { image: AcademiesLogo.academy19 },
  { image: AcademiesLogo.academy20 },
  { image: AcademiesLogo.academy21 },
  { image: AcademiesLogo.academy22 },
  { image: AcademiesLogo.academy23 },
  { image: AcademiesLogo.academy24 },
  { image: AcademiesLogo.academy25 },
  { image: AcademiesLogo.academy26 },
  { image: AcademiesLogo.academy27 },
  { image: AcademiesLogo.academy28 },
  { image: AcademiesLogo.academy29 },
  { image: AcademiesLogo.academy30 },
  { image: AcademiesLogo.academy31 },
  { image: AcademiesLogo.academy32 },
  { image: AcademiesLogo.academy33 },
  { image: AcademiesLogo.academy34 },
  { image: AcademiesLogo.academy35 },
  { image: AcademiesLogo.academy36 },
  { image: AcademiesLogo.academy37 },
  { image: AcademiesLogo.academy38 },
  { image: AcademiesLogo.academy39 },
  { image: AcademiesLogo.academy40 },
  { image: AcademiesLogo.academy41 },
  { image: AcademiesLogo.academy42 },
];
